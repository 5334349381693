import { useEffect, useState } from "react";

/**
 * Repurposed the suggestion from: https://stackoverflow.com/a/71774031
 */
export const useDevicePixelRatio = () => {
  const [devicePixelRatio, setState] = useState<number>(1);

  useEffect(() => {
    const onChange = () => {
      setState(window.devicePixelRatio);
    };

    const mediaQueryList = matchMedia(`(resolution: ${devicePixelRatio}dppx)`);

    mediaQueryList.addEventListener("change", onChange, {
      once: true,
      passive: true,
    });

    onChange();

    return () => mediaQueryList.removeEventListener("change", onChange);
  }, [devicePixelRatio]);

  return devicePixelRatio;
};
