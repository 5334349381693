import { KeyboardEvent } from "react";
import classnames from "classnames/bind";
import { DropdownRail } from "../../../../__generated__/contentful-types";
import { notNull } from "../../../../helpers/notNull";
import styles from "./support-dropdown.module.css";

const cx = classnames.bind(styles);

export const SupportDropdown = ({
  props,
  isExpanded,
  setIsExpanded,
  noLogo,
}: {
  props: DropdownRail;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  noLogo?: boolean;
}) => {
  return (
    <div className={cx("container")}>
      <div
        className={cx("dropdown", {
          "dropdown--expanded": isExpanded,
        })}
        aria-expanded={isExpanded ? "true" : "false"}
        tabIndex={0}
        role="listbox"
        onFocus={() => {
          if (!isExpanded) {
            setIsExpanded(true);
          }
        }}
        onBlur={(event) => {
          if (
            event.relatedTarget === null &&
            isExpanded &&
            event.target instanceof HTMLElement
          ) {
            event.target.scrollTop = 0;
            setIsExpanded(false);
          }
        }}
        onKeyDown={(event) => keyControls(event, setIsExpanded)}
      >
        <div
          className={cx("label", {
            "label--no-logo": noLogo,
          })}
          onMouseDown={() => {
            if (isExpanded) {
              setIsExpanded(false);
              return;
            }
            setIsExpanded(true);
          }}
        >
          {props.text}
        </div>
        <div className={cx("options")}>
          {props.dropdownItemsCollection?.items
            .filter(notNull)
            .map((dropdownItem, index) => (
              <a
                key={index}
                className={cx("option")}
                href={dropdownItem.linkUrl ?? undefined}
                tabIndex={-1}
                onBlur={(event) => {
                  if (
                    event.relatedTarget === null &&
                    isExpanded &&
                    event.target instanceof HTMLElement
                  ) {
                    event.target.scrollTop = 0;
                    setIsExpanded(false);
                  }
                }}
              >
                {dropdownItem.title}
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

function keyControls(
  event: KeyboardEvent,
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>,
): void {
  const option =
    event.target === event.currentTarget &&
    event.currentTarget instanceof Element
      ? event.currentTarget.children[1].firstElementChild
      : event.target;

  if (event.key === "Escape" || event.key === "Tab") {
    setIsExpanded(false);
    if (option instanceof HTMLElement) {
      option.blur();
    }
    if (event.currentTarget instanceof HTMLElement) {
      event.currentTarget.blur();
    }
  }
  if (event.key === "Enter") {
    if (option instanceof HTMLElement) {
      option.click();
    }
  }

  if (event.key === "ArrowUp") {
    event.preventDefault();
    if (
      option instanceof Element &&
      option.previousElementSibling instanceof HTMLElement
    ) {
      option.previousElementSibling.focus();
    }
  }

  if (event.key === "ArrowDown") {
    event.preventDefault();
    if (
      option instanceof Element &&
      option.nextElementSibling instanceof HTMLElement
    ) {
      option.nextElementSibling.focus();
    }
  }
}
