"use client";

import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap";
import { SupportModule } from "./support-module/support-module";
import classnames from "classnames/bind";
import { BlocksSupportCta } from "../../../__generated__/contentful-types";
import { isArtwork } from "../../../helpers/typeguards";
import { notNull } from "../../../helpers/notNull";
import {
  ExplodedWordsToSpans,
  waitForScrollConfig,
} from "../interactive-hero/utils/interactive-hero-utils";
import { useLayoutEffect, useRef } from "react";
import styles from "./support-cta.module.css";

const cx = classnames.bind(styles);

export interface SupportCtaProps {
  block: BlocksSupportCta;
  animated?: boolean;
}

export const SupportCta = ({ block, animated }: SupportCtaProps) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLHeadingElement | null>(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (!animated) {
      return;
    }
    const ctx = gsap.context(() => {
      const rootElement = rootRef.current;
      const titleElement = titleRef.current;
      const modulesElements = rootElement?.querySelectorAll(
        '[data-support-cta="support-module"]',
      );
      const timeline = gsap.timeline();
      timeline.from(titleElement ?? null, {
        duration: 0.4,
        y: "+=60%",
        opacity: 0,
        stagger: 0.1,
      });
      timeline.from(modulesElements ?? null, {
        duration: 0.4,
        y: "+=10%",
        opacity: 0,
        stagger: 0.1,
      });
      timeline.pause();
      const scrollTrigger = new ScrollTrigger({
        trigger: rootElement,
        start: "top 70%",
        animation: timeline,
        once: true,
      });
      scrollTrigger.disable();
      waitForScrollConfig().then(() => {
        scrollTrigger.enable();
      });
    });

    return () => ctx.revert();
  }, [animated]);

  return (
    <div ref={rootRef} className={cx("container")}>
      <div className={cx("inner")}>
        {block.title && (
          <h2 ref={titleRef} className={cx("title")}>
            {animated ? (
              <ExplodedWordsToSpans text={block.title} />
            ) : (
              block.title
            )}
          </h2>
        )}
        <div
          className={cx("support-modules", {
            "support-modules--no-gap":
              block.supportModulesCollection?.items.find((module) =>
                isArtwork(module),
              ),
          })}
        >
          {block.supportModulesCollection?.items
            .filter(notNull)
            .map((module, index) => (
              <div
                data-support-cta="support-module"
                key={index}
                className={cx("support-module", {
                  "support-module-artwork--padding": isArtwork(module),
                })}
              >
                <SupportModule module={module} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
